@keyframes upAndDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.landingPage {
  #about {
    .mouse {
      animation: upAndDown 1.5s ease-in-out infinite;
    }
  }
}
