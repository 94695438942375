
.container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.image-preview {
  margin-top: 10px;
  text-align: left;
  img {
    display: block;
    margin-bottom: 5px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 20px;
  color: #555;
  margin-top: 30px;
  margin-bottom: 10px;
}

.product-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  .label {
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
}

.input-field {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s;
  :focus {
    border-color: #007bff;
  }
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  :hover {
    background-color: #0056b3;
  }
}

.product-list {
  list-style-type: none;
  padding: 0;
}

.product-item {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  :nth-child(even) {
    background-color: #f1f1f1;
  }
}
.input-error {
  border-color: red;
}
.error-text {
  color: red;
  font-size: 14px;
}
