.header {
  height: 6.625rem;
  .navigate-menu {
    .menu-item {
      transition: all 0.3s ease; 
    }
    .menu-item:hover {
      font-weight: 500;
      background-color: #007bff; 
      color: white;
      border-radius: 5px; 
    }
    .active {
      font-weight: 700;
    }
  }
}
.section {
  scroll-margin-top: 80px; 
}

.sidebar {
  width: 18.25rem;
  padding-top: 0.9rem;
  transition: all 0.4s ease;

  .sidebar-navigate {
    margin-top: 3.2rem;
    .active {
    
      color: var(--Xanh-Base);
      .sidebar-menu-container {
        background-color: var(--Xanh-10);
        border-radius: 0.5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }

    .navigate-menu {
      width: 15.5rem;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      text-decoration: none !important;
      transition: all 0.4s ease;

      .sidebar-menu-container {
        width: 100%;
        height: 100%;
        padding: 0rem 1rem;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        transition: all 0.4s ease;
        transition: color 0.05s ease;
        overflow: hidden;

        .icon {
          font-size: 2rem;
          transition: all 0.4s ease;
          transition: color 0.05s ease;
          margin-right: 1rem;
          width: fit-content;
        }
        .label {
          pointer-events: none;
          opacity: 1;
          text-wrap: nowrap;
          text-align: start;
          font-size: 1rem;
          width: fit-content;
          transition: all 0.4s ease;
          transition: color 0.05s ease;
        }
      }
    }
    .navigate-menu:hover {
      .sidebar-menu-container {
        background-color: var(--Xanh-10);
        border-radius: 0.5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        color: var(--text-main-color);
        transition: all 0.4s ease;
        transition: color 0.05s ease;
        .icon {
          font-size: 2rem;
        }
        .label {
        }
      }
    }
  }
}

.slide-out {
  width: 6.5rem;
  .sidebar-navigate {
    .active {
      color: var(--Xanh-Base);
      .sidebar-menu-container {
        background-color: var(--Xanh-10);
        border-radius: 0.5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
    .navigate-menu {
      width: 5rem;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .sidebar-menu-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        transition: all 0.4s ease;
        transition: color 0.05s ease;

        .icon {
          font-size: 2rem;
          transition: all 0.4s ease;
          transition: color 0.05s ease;
          margin-right: 0;
          transform: translate(0%, -25%);
        }
        .label {
          pointer-events: none;
          opacity: 1;
          position: absolute;
          width: 5rem;
          text-align: center;
          bottom: 5%;
          font-size: 0.87rem;
        }
      }
    }
    .navigate-menu:hover {
      width: 5rem;
      height: 5rem;
      color: var(--text-main-color);

      .sidebar-menu-container {
        width: 100%;
        height: 100%;
        background-color: var(--Xanh-10);
        border-radius: 0.5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        .icon {
          font-size: 2rem;
          transform: translate(0%, -25%);
        }
        .label {
          opacity: 1;
          position: absolute;
          bottom: 5%;
          font-size: 0.87rem;

        }
      }
    }
  }
}

#toggle_checkbox {
  display: none;
}
.background-theme {
  display: block;
  position: relative;
  width: 56px;
  height: 27px;
  margin: 0 auto;
  background-color: #77b5fe;
  border-radius: 27px;
  cursor: pointer;
  transition: 0.3s ease background-color;
  overflow: hidden;
}

#star {
  position: absolute;
  top: 6.5px;
  left: 6.5px;
  width: 14.5px;
  height: 14.5px;
  background-color: #fafd0f;
  transform: scale(1);
  border-radius: 50%;
  transition: 0.3s ease top, 0.3s ease left, 0.3s ease transform,
    0.3s ease background-color;
  z-index: 0;
}

#star-1 {
  position: relative;
}

#star-2 {
  position: absolute;
  transform: rotateZ(36deg);
}

.star {
  top: 0;
  left: -3px;
  font-size: 26px;
  line-height: 13.5px;
  color: #fafd0f;
  transition: 0.3s ease color;
}

#moon {
  position: absolute;
  bottom: -25px;
  right: 4px;
  width: 19px;
  height: 19px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s ease bottom;
}

#moon:before {
  content: "";
  position: absolute;
  top: -5.5px;
  left: -8px;
  width: 19px;
  height: 19px;
  background-color: #03a9f4;
  border-radius: 50%;
  transition: 0.3s ease background-color;
}

#toggle_checkbox:checked + label {
  background-color: #000;
}

#toggle_checkbox:checked + label #star {
  top: 1.5px;
  left: 30px;
  transform: scale(0.3);
  background-color: yellow;
}

#toggle_checkbox:checked + label .star {
  color: yellow;
}

#toggle_checkbox:checked + label #moon {
  bottom: 4px;
}

#toggle_checkbox:checked + label #moon:before {
  background-color: #000;
}
