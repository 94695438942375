:root {
  --gray-menu-color: #f7f7f7;

  --oposite-main-color: #000000;
  --main-color: #ffffff;
  --second-color: #f2f0f0;
  --line-main-color: #dddddd;
  --line-oposite-color: #000000;
  --main-project-color: #ffca54;
  --text-main-color: black;
  --text-second-color: #b2b2b2;

  --en-vu: #091540;
  --en-vu-600: #848a9f;
  --en-vu-500-disable: #adb1bf;
  --en-vu-400: #c6cad8;
  --en-vu-300: #d5d7df;
  --en-vu-200: #ebecf2;
  --en-vu-100: #fbfbfb;
  --en-vu-10-white: #fff;
  --Xanh-700: #0a9a63;
  --Xanh-Base: #0db977;
  --Xanh-300: #86dcbb;
  --Xanh-200: #aee7d1;
  --Xanh-100: #d6f3e8;
  --Xanh-10: #f0fbf7;

  --Do-Base: #f02656;
  ---100: #fcdae2;
}
[data-theme="dark"] {
  --oposite-main-color: #ffffff; 
  --main-color: #1e1e1e; 
  --second-color: #2e2e2e;
  --line-main-color: #444444; 
  --line-oposite-color: #ffffff; 
  --main-project-color: #ffca54; 
  --text-main-color: #f5f5f5; 
  --text-second-color: #cccccc; 
}

.profile {
  .active {
    background-color: #374151;
    color: white;
  }
}
.notification-bell {
  background-color: #e4e6eb;
  color: black;
  :hover {
    color: #3b82f6;
  }
  .active {
    color: #3b82f6;
  }
}

.loading {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
  cursor: not-allowed;
  left: 0.25rem;
  top: 0.5rem;
}
.loading-button {
  opacity: 90%;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2.5rem;
  .loading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 2.5rem;
    transform: translateY(-0.25rem);
  }
  .dot {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 100%;
    background-color: var(--main-color);
    animation: bounces 1.6s infinite;
  }
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  .dot:nth-child(2) {
    animation-delay: 0.3s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.6s;
  }
}

.action-tab-container {
  position: absolute;
  left: 0;
  background-color: white;

  text-align: center;
  width: fit-content;
  height: fit-content;
  text-wrap: nowrap;
  box-shadow: #1e1e1e;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  z-index: 1;
  transform: translateX(-100%);

  > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  > * {
    padding: 0.5rem 1rem;
  }
  > :first-child {
    padding-top: 1rem;
  }
  > :last-child {
    padding-bottom: 1rem;
  }

  :hover {
    background-color: #cccccc;
  }
}

.detail-slider {
  background-color: rgba($color: #000000, $alpha: 0.2);
  overflow: auto;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  > * {
    position: absolute;
    right: 0;
  }
}
.over-detail-slider {
  z-index: 110;
}

.skeleton {
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}
#buttonDiv {
  display: block;
  visibility: visible;
  width: 100%;
  height: auto;
}

@keyframes shimmer {
  from {
    background-position: -200% 0;
  }
  to {
    background-position: 200% 0;
  }
}

.skeleton-row {
  height: 1.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
}
.text-clip {
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}

@keyframes bounces {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.5rem);
  }
}
